// @flow

import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {useCookies} from "react-cookie";
import {useLocation, useNavigate} from "react-router-dom";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import {LanguageSelect} from "../LanguageSelector/LanguageSelect";
// $FlowFixMe
import {useMatch} from "react-router";


type PropTy = {
    currentLanguage: string,
}
const styles = (theme:ThemeTy) => ({
    languageSelect: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0.25),
    },
});

const useStyles = makeStyles(styles);

export const LanguageTopBarSelector: React.AbstractComponent<$Shape<PropTy>> = observer(function LanguageTopBarSelector(props: PropTy) {
    const {currentLanguage} = props;
    const classes:CssClassesTy = useStyles();
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    const [cookie, setCookie,] = useCookies(["language", "consentoptional"]);

    //const currentMatchUrl = match.url === '/' ? '' : match.url;
    //const endLocation = location.pathname.slice(currentMatchUrl.length);

    const langList = dictionaryManager.getLanguageList();
    const m = useMatch('/:lan/*')
    const p = m ? m.params['*'] : '';
    const navigate = useNavigate();


    function handleChange(ev: SyntheticEvent<HTMLAnchorElement>, value: string) {
        if (typeof value === 'string') {
            if (cookie.consentoptional === '1') {
                const newpath = "/" + value + (p ? "/" + p : "");
                navigate(newpath)
                setCookie("language", value, {expires: new Date('01-01-2038'), path: '/'});
            }
        }
    }
    return <>
        <LanguageSelect
            className={classNames(classes.languageSelect)}
            value={(langList && langList.has(currentLanguage)) ? currentLanguage : ""}
            onChange={handleChange}
            shortDisplay={true}
            contrastMain={true}
        />
    </>;
});
