// @flow

import * as React from 'react'

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {styles} from "../styles";
import {Link} from "react-router-dom";
import type {CssClassesTy} from "../../custom-types/TypeDefs";
import {makeStyles} from '@material-ui/core/styles';



type PropTy = {
    text?: ?(string | React.Node),
    onSelectElement: (e: SyntheticEvent<HTMLElement>, v: string) => mixed,
    route: string,
    icon: ?React.Node,
    selected?: ?boolean,
}
const useStyles = makeStyles(styles);

export function ClickableListItem(props:PropTy): React.Element<'li'> {
    const classes:CssClassesTy = useStyles();
    const r = props.route;
    function handleSelectElement(e: SyntheticEvent<HTMLElement>) {
        props.onSelectElement(e, r);
    }

    return (<li>
        <Link to={r} className={classes.anchorAsText} onClick={handleSelectElement}>
            <ListItem button
                      selected={props.selected}
                      className={classes.sideBarElement}
            >
                <ListItemIcon className={classes.sideBarIcon}>
                    {props.icon}
                </ListItemIcon>
                {props.text && <ListItemText primary={props.text}/>}
            </ListItem>
        </Link>
    </li>);
}

