// @flow

import * as React from "react";
import {observer} from "mobx-react-lite";

import {useParams} from "react-router";
import {NoLanguageView} from "./NoLanguageView";
import {LanguageContent} from "./LanguageContent";
import {locale_regex} from "./languageHelpers.js";

type LanguageViewProps = {
    defaultLanguage: string,
}

export const LanguageView: React.AbstractComponent<$Shape<LanguageViewProps>> = observer(function (props: LanguageViewProps) {
    const {defaultLanguage} = props;
    const params = useParams();

    const lang = params.lang;


    if (!lang || !lang.match(locale_regex)) {
        return <NoLanguageView defaultLanguage={defaultLanguage}/>
    }
    return <LanguageContent language={lang}/>
});