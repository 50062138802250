// @flow

import * as React from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {LanguageCtx} from "../../stores/LanguageCtx";
import {styles as outer_styles} from "../styles";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";
import {AccountTopBarIcon} from "./AccountTopBarIcon";
import {LanguageTopBarSelector} from "./LanguageTopBarSelector";

const styles = (theme: ThemeTy) => ({
    toolbar: {
        paddingRight: theme.spacing(2), // keep right padding when drawer closed
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0),
        },
    },
    title: {
        flexGrow: 1,
        fontFamily: "din-2014, sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "1.5rem",
    },
});

type PropTy = {
    onOpenDrawer: () => mixed,
    onRequestLogin: () => mixed,
    onRequestLogout: () => mixed,
    onOpenAccountManagement: () => mixed,
    onOpenMessageCenter: () => mixed,
    currentLanguage: string,
    loggedIn: boolean,
}

const useStyles = makeStyles(outer_styles);
const useLocalStyles = makeStyles(styles);

export const TopBar: React.AbstractComponent<$Shape<PropTy>> = observer(function TopBar(props: PropTy) {
    const {
        onOpenDrawer,
        onRequestLogin,
        onRequestLogout,
        onOpenAccountManagement,
        onOpenMessageCenter,
        loggedIn,
    } = props;
    const [language,] = React.useContext(LanguageCtx);
    const nav = useNavigate();

    const classes:CssClassesTy = useStyles();
    const localClasses: CssClassesTy = useLocalStyles();

    function handleDrawerOpen() {
        onOpenDrawer();
    }

    return (
        <AppBar
            position="relative"
            className={classNames(classes.appBar)}
        >
            <Toolbar disableGutters={true} className={localClasses.toolbar}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerOpen}
                    className={classNames(
                        classes.menuButton,
                    )}
                >
                    <MenuIcon />
                </IconButton>
                <img src={"/allsports.svg"}
                     alt={"AllSports"}
                     className={classNames(classes.menuButton,)}
                     width={48}
                     height={48}
                     onClick={() => {
                         nav('/home');
                     }}
                />
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={localClasses.title}
                    // style={{
                    //     fontFamily: "din-2014, sans-serif",
                    //     fontWeight: 600,
                    //     fontStyle: "normal",
                    //     fontSize: "1.5rem",
                    // }}
                >
                    {'AllSports'}
                </Typography>
                <AccountTopBarIcon onRequestLogin={onRequestLogin}
                                   onRequestLogout={onRequestLogout}
                                   onOpenAccountManagement={onOpenAccountManagement}
                                   onOpenMessageCenter={onOpenMessageCenter}
                                   loggedIn={loggedIn}
                />
                {!loggedIn && <LanguageTopBarSelector
                    currentLanguage={language}
                />}
            </Toolbar>
        </AppBar>

    )
});

