// @flow

import type {FetcherBase} from "../Fetchers/FetcherBase";

export type SavableStore<T> = {
    saveData: (T,string) => Promise<mixed>,
}


export class ConnectStore {
    conn: FetcherBase;
    constructor(conn: FetcherBase) {
        this.conn = conn;
    }
}

export class BaseStore extends ConnectStore {
}
