// @flow

import {HTTPStatusError} from "../errors/FetchError";
import type {ProtoFetchOptions} from "./FetcherBase";
import {FetcherBase} from "./FetcherBase";
import type {PlainJsonTy} from "../custom-types/TypeDefs";

export class FetcherUrl extends FetcherBase {
    async fetchData(base_url: string, options: $ReadOnly<ProtoFetchOptions>): Promise<PlainJsonTy> {
        const outputOptions = FetcherBase.buildOptions(options);
        const url = this.buildUrl(base_url, options);
        const response = await fetch(url.toString(), outputOptions);
        if (response.ok) {
            const content_type = response.headers.get('content-type');
            if (!content_type) {
                return {};
            } else if (content_type.startsWith('application/json')) {
                const txt = await response.text();
                if (txt) {
                    return JSON.parse(txt);
                } else {
                    return {};
                }
            } else {
                throw new HTTPStatusError({url, options: {...options}, responseBlob: await response.blob()},
                    'Bad content type', 415)
            }
        } else {
            throw new HTTPStatusError({url: url, options: {...options}}, await response.text(), response.status)
        }
    }

    async fetchImageData(base_url: string, options: $ReadOnly<ProtoFetchOptions>): Promise<Blob> {
        const outputOptions = FetcherBase.buildOptions(options);
        const url = this.buildUrl(base_url, options);
        const response = await fetch(url.toString(), outputOptions);
        if (response.ok) {
            const content_type = response.headers.get('content-type');
            if (content_type && content_type.startsWith('image')) {
                return await response.blob();
            } else {
                throw new HTTPStatusError({url, options: {...options}, responseBlob: await response.blob()},
                    'Bad content type', 415)
            }
        } else {
            throw new HTTPStatusError({url: url, options: {...options}}, await response.text(), response.status)
        }
    };
}
