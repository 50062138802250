// @flow

import Popover from "@material-ui/core/Popover";
import classNames from "classnames";
import {Input} from "@material-ui/core";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {LanguageSelectPaper} from "./LanguageSelectPaper";

const styles = (theme: ThemeTy) => ({
    root: {
        '&:focus-within': {
            '&:after': {
                transform: 'scaleX(1)',
            }
        },
    },
    rootLight: {
        color: theme.palette.getContrastText(theme.palette.primary.main),

    },
    input: {
        left: 0,
        bottom: 0,
        opacity: 0,
        width: '100%',
        position: 'absolute',
        pointerEvents: 'none',
    },
    selectDiv: {
        fontSize: '0.75rem',
        paddingRight: theme.spacing(1.5),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        minWidth: theme.spacing(3),
        paddingTop: 6,
        paddingBottom: 7,
        userSelect: 'none',
        minHeight: 'calc(0.75 * 1.1876em)',
        borderRadius: 0,
        border: 0,
        margin: 0,
        display: 'block',
        background: 'none',
        boxSizing: 'content-box',
        '-webkit-tap-highlight-color': 'transparent',
        '&:focus': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: 0,
            outline: 0,
        },
        //color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    selectAdornment: {
        top: 'calc(50% - 12px)',
        //color: theme.palette.getContrastText(theme.palette.primary.main),
        right: 0,
        position: 'absolute',
        pointerEvents: 'none',
        width: '0.75em'
    },

    underlineAfterLight: {
        '&:hover:not(.MuiDisabled):before': {
            borderBottomColor: theme.palette.getContrastText(theme.palette.primary.main),
        },
        '&:before': {
            borderBottomColor: theme.palette.getContrastText(theme.palette.primary.main),
        },
        '&:after': {
            borderBottomColor: theme.palette.getContrastText(theme.palette.primary.main),
        }
    },

});
export type LanguageButtonPropTy = {
    value: string,
    name: string,
    onSelect: (e: SyntheticEvent<HTMLAnchorElement>, value: string) => mixed,
}

type PropTy = {
    value: string,
    onChange: ?((SyntheticEvent<HTMLAnchorElement>, value: string) => mixed),
    className?: string,
    languageButtonComponent?: React.AbstractComponent<LanguageButtonPropTy>,
    shortDisplay?: ?boolean,
    contrastMain?: ?boolean,
}
const useLocalStyles = makeStyles(styles);


export const LanguageSelect: React.AbstractComponent<$Shape<PropTy>> = observer(function LanguageSelect(props: PropTy) {
    const {value, onChange, className, languageButtonComponent, shortDisplay, contrastMain} = props;
    const local_classes: CssClassesTy = useLocalStyles();
    const [open, setOpen] = React.useState(false);
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    const inputRef = React.useRef();
    function splitLanguageShortName(locName: string): string[] {
        return locName.split('-');
    }
    const displayValue = !shortDisplay ? (
        dictionaryManager.getLanguageName(value) ||
            (splitLanguageShortName(value)[0]).toUpperCase()) :
        (splitLanguageShortName(value)[0]).toUpperCase();

    return <>
        <Input
            className={classNames(local_classes.root, contrastMain ? local_classes.rootLight : undefined, className)}
            classes={{
                input: classNames(local_classes.input),
                underline: contrastMain ? classNames(local_classes.underlineAfterLight) : undefined,
            }}
            value={displayValue}
            disableUnderline={false}
            readOnly={true}
            inputProps={{
                tabIndex: -1,
                className: local_classes.input,
                style: {cursor: 'pointer'},
                "aria-hidden": true,
            }}
            startAdornment={
                <div
                    className={local_classes.selectDiv}
                    tabIndex={0}
                    role={"button"}
                    onClick={() => {
                        setOpen(o => !o)
                    }}
                >
                    {displayValue}
                    <ArrowDropDownIcon
                        className={local_classes.selectAdornment}
                        transform={open ? "scale(1,-1)" : "scale(1,1)"}
                    />
                </div>
            }
            style={{cursor: 'pointer'}}
            inputRef={inputRef}>
        </Input>
        <Popover open={open}
                 anchorEl={inputRef.current}
                 onClose={() => setOpen(false)}
        >
            <LanguageSelectPaper
                onChange={(e, v) => {
                    if (onChange) {
                        onChange(e, v);
                    }
                    setOpen(false);
                }}
                languageButtonComponent={languageButtonComponent}
            />
        </Popover>

    </>;
});
