// @flow

import {BASE_URL} from "../../constants";
import type {PlainArrayTy, PlainJsonTy} from "../../custom-types/TypeDefs";
import type {FetcherBase} from "../../Fetchers/FetcherBase";
import {ConnectStore} from "../BaseStore";
import {action, makeObservable, observable} from "mobx";

export class DictionaryStore extends ConnectStore {
    loadedLanguageDict: Map<string, Map<string, string>> = observable.map(new Map(), {deep: true});
    mappedLanguages: Map<string, string|null> = observable.map(new Map(), {deep: false});
    languageList: ?Map<string, string> = undefined;
    language_counter: number = 0;
    currentlyLoading: string = '';
    static dictionary_url: string = '/dictionary';
    static get_lang_list: string = '/dictionary/language-list';
    showDebug: boolean = false;
    showDebugForced: boolean = false;
    showDebugDisplay: boolean = false;
    myGroup: string;

    constructor(conn: FetcherBase, group: string) {
        super(conn);
        this.myGroup = group;
        this.language_counter = 1;
        makeObservable(this, {
            loadedLanguageDict: observable,
            languageList: observable,
            currentlyLoading: observable,
            mappedLanguages: observable,
            showDebug: observable,
            showDebugForced: observable,
            showDebugDisplay: observable,
            language_counter: observable,
            loadLanguage: action,
        });
    }

    transformLanguageData(data: {+[string]: mixed}): Map<string, string> {
        const dict = new Map<string, string>();
        for (const [key, val] of Object.entries(data)) {
            if (typeof val === 'string') {
                dict.set(key, val);
            }
        }
        return dict;
    }

    async loadLanguage(key: string): Promise<?string> {
        if (this.currentlyLoading !== key) {
            this.currentlyLoading = key;
            try {
                const languageData: PlainJsonTy|PlainArrayTy = await this.conn.fetchData(
                    BASE_URL() + DictionaryStore.dictionary_url + '/' + key,
                    {
                        method: 'GET',
                        query: {
                            group: this.group,
                        }
                    }
                );
                this.mappedLanguages.set(key, null);
                if (typeof languageData === 'object' && languageData && !Array.isArray(languageData)) {
                    const {language, dictionary} = languageData;
                    if (typeof language === 'string' && typeof dictionary === 'object' && dictionary && !Array.isArray(dictionary)) {
                        const langMap = this.transformLanguageData(dictionary);
                        this.loadedLanguageDict.set(language, langMap);
                        this.mappedLanguages.set(key, language);
                        this.language_counter+=1;
                        // $FlowFixMe
                        return language;
                    }
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.currentlyLoading = '';
            }
        }
    }

    hasLanguage(key: string): boolean {
        const k = this.mappedLanguages.get(key);
        if (!k) {
            return false;
        }
        return !!this.loadedLanguageDict.get(k ? k : key);
    }

    get group(): string {
        return this.myGroup;
    }

    get(languageKey: string, key: string, def: string = ""): string {
        if (this.showDebug) {
            return `[${this.group}] ${key}`;
        }
        const k = this.mappedLanguages.get(languageKey);
        if (k === null) { //language has failed to load.
            return def;
        }
        const langMap: ?Map<string, string> = this.loadedLanguageDict.get(k ? k : languageKey);
        if (!langMap) {
            return "";
        } else {
            const v = langMap.get(key);
            if (v) return v;
            return def;
        }
    }

    getFallBack(languageKey: string, key: string, def: string = ""): string {
        if (this.showDebug) {
            return `[${this.group}] ${key}`;
        }
        const k = this.mappedLanguages.get(languageKey);
        if (k === null) { //language has failed to load.
            return def;
        }
        const langMap: ?Map<string, string> = this.loadedLanguageDict.get(k ? k : languageKey);
        if (!langMap) {
            return def;
        } else {
            const v = langMap.get(key);
            if (v) return v;
            return def;
        }
    }

    getFullLanguage(languageKey: string): void|(Map<string, string>) {
        const k = this.mappedLanguages.get(languageKey);
        if (!k) {
            return undefined;
        }
        const langMap: void|(Map<string, string>) = this.loadedLanguageDict.get(k ? k : languageKey);
        return langMap;
    }

}
