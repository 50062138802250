// @flow

import * as React from 'react';
import {observer} from "mobx-react-lite";
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import {LanguageCtx} from "../../stores/LanguageCtx";
import {styles as outer_styles} from "../styles";
import type {CssClassesTy} from "../../custom-types/TypeDefs";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {StartId} from "../custom-icons/StartId";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from '@material-ui/core/styles';

type PropTy = {
    onRequestLogin: () => mixed,
    onRequestLogout: () => mixed,
    onOpenAccountManagement: () => mixed,
    onOpenMessageCenter: () => mixed,
    loggedIn: boolean,
}

const useStyles = makeStyles(outer_styles);

export const AccountTopBarIcon: React.AbstractComponent<$Shape<PropTy>> = observer(function AccountTopBarIcon(props: PropTy) {
    const {onRequestLogin, onRequestLogout, onOpenAccountManagement, onOpenMessageCenter, loggedIn} = props;
    const classes:CssClassesTy = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null|HTMLButtonElement>(null);
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    const dictionaryStore = React.useMemo(() => dictionaryManager.addDictionaryGroup('entry'), [dictionaryManager]);
    const [language,] = React.useContext(LanguageCtx);

    function handleLogin() {
        onRequestLogin();
    }

    function handleLogout() {
        onRequestLogout();
    }

    function handleOpenAccountManagement() {
        onOpenAccountManagement();
    }

    function handleOpenMessageCenter() {
        onOpenMessageCenter();
    }

    function handleOpen(event: SyntheticEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(reason?: ?('logout' | 'profile' | 'contact')) {
        setAnchorEl(null);
        switch (reason) {
            case 'logout':
                handleLogout();
                break;
            case "profile":
                handleOpenAccountManagement();
                break;
            case "contact":
                handleOpenMessageCenter();
                break;
            default:
                break;
        }
    }

    return <>
        <Button
            variant="outlined"
            color="inherit"
            onClick={handleLogin}
            className={classNames(
                loggedIn && classes.elemHidden,
            )}
        >
            <StartId/>&nbsp;
            <Typography
                //variant="body2"
                color="inherit"
                noWrap
                className={classes.title}
            >
                {dictionaryStore.get(language, 'btn-login', "login")}
            </Typography>
        </Button>
        <IconButton
            className={classNames(!loggedIn && classes.elemHidden)}
            onClick={handleOpen}
            color="inherit"
            aria-label="Account"
        >
            <StartId/>
        </IconButton>
        <Menu
            id="logout-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => handleClose('profile')}>
                {dictionaryStore.get(language, 'hdr-profile', "Profile")}
            </MenuItem>
            <MenuItem onClick={() => handleClose('contact')}>
                {dictionaryStore.get(language, 'hdr-messages', "Messages")}
            </MenuItem>
            <MenuItem onClick={() => handleClose('logout')}>
                {dictionaryStore.get(language, 'hdr-logout', "Logout")}
            </MenuItem>
        </Menu>
    </>;
});
