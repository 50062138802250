// @flow

import classNames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {styles as outer_styles} from "../styles"
import {useCookies} from "react-cookie";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";

const styles = (theme: ThemeTy) => ({
    header: {
        display: 'flex',
    },
    name: {
        flexGrow: 1,
    },
    help: {
        whiteSpace: 'pre-wrap',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
});

type PropTy = {
    name: string,
    cookie: string,
    help: string,
    disabled?: ?boolean,
}
const useStyles = makeStyles(outer_styles);
const useLocalStyles = makeStyles(styles);

export const SingleCookieSetting: React.AbstractComponent<PropTy> = observer(function SingleCookieSetting(props: PropTy) {
    const {name, cookie, help, disabled} = props;
    const classes: CssClassesTy = useStyles();
    const local_classes: CssClassesTy = useLocalStyles();
    const [cookies, setCookie, removeCookie] = useCookies([cookie]);
    const [helpOpen, setHelpOpen] = React.useState(false);

    const isActive = cookies[cookie] === "1";

    function activate(key: string) {
        setCookie(key, "1", {expires: new Date('01-01-2038'), path: '/'});
    }
    function deactivate(key: string) {
        removeCookie(key, {path: '/'});
    }


    return <>
        <ListItem className={classNames(classes.paperTitleListItem, local_classes.header)}
                  button={true}
                  onClick={() => setHelpOpen(o => !o)}>
            <Typography variant={"subtitle2"} className={local_classes.name}>
                {name}
            </Typography>
            <Switch
                checked={isActive}
                onClick={e => {
                    e.stopPropagation();
                }}
                onChange={(e, c) => {
                    if (c) {
                        activate(cookie);
                    } else {
                        deactivate(cookie);
                    }
                }}
                disabled={disabled}
            />
        </ListItem>

        {help && <Collapse in={helpOpen}>
            <Typography className={local_classes.help}>
                {help}
            </Typography>
        </Collapse>}
    </>;
});
