// @flow

import type {ThemeTy} from "../custom-types/TypeDefs";

const drawerWidth = 240;

export const styles: ((theme:ThemeTy) => {+[string]: {+[string]: mixed}}) = (theme:ThemeTy) => {
    return ({

        '@global': {
            a: {
                color: theme.palette.primary.main,
            }
        },

        tightDivider: {
            marginBottom: theme.spacing(0.5)
        },
        hiddenFieldSet: {
            margin: 0,
            border: 0,
            padding: 0,
        },

        body: {
            height: '100%',
        },

        ButtonLikeText: {
            ...theme.typography.button
        },
        root: {
            display: 'flex',
            position: 'relative',
        },
        hdrMain: {
            flexShrink: 1,
            flexGrow: 1,
            width: 180,
        },
        hdrRightAlign: {
            display: 'flex',
            justifyContent: 'flex-end',
            minWidth: '100px',
            marginRight: theme.spacing(2),
            alignItems: 'center',
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: `0 ${theme.spacing(1)}px`,
            minHeight: 48,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            maxHeight: 48,
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(0.5),
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
            },
        },
        menuButtonHidden: {
            display: 'none',
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(6),
            },
        },
        appBarSpacer: theme.mixins.toolbar,
        mainDiv: {
            width: '100%',
            height: '100%',
            flexGrow: 1,
            flexDirection: 'column',
            position: 'relative',
            display: 'flex',
            '&:focus': {
                borderRadius: 0,
                outline: 0,
            },
        },
        content: {
            flexGrow: 1,
            //padding: theme.spacing(3),
            overflow: 'auto',
            position: 'relative'
        },
        h5: {
            marginBottom: theme.spacing(2),
        },

        errorInfoPaperError: {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.dark,
        },
        errorInfoPaperInfo: {
            backgroundColor: theme.palette.grey[0],
            borderColor: theme.palette.grey[1000],
        },
        errorInfoPaper: {
            borderWidth: 1,
            borderStyle: 'solid',
            padding: theme.spacing(1 / 2),
            paddingLeft: '5%',
            paddingRight: theme.spacing(4),
            display: 'flex',
            alignItems: 'center',
            margin: 0,
        },
        errorText: {
            color: theme.palette.error.main,
        },

        sideBarIcon: {
            marginRight: 0,
        },

        anchorAsText: {
            color: 'unset',
            textDecoration: 'unset',
        },
        sideBarElement: {
            padding: 11,
            overflowX: "hidden",
        },
        sideBarOpenIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 0,
        },
        sideBarOpenMobileIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            minHeight: 56,
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                minHeight: 48,
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: 64,
            },

        },

        pageRoot: {
            height: '100%',
            position: 'relative',
            overflow: 'auto',

            display: 'flex',
            flexDirection: 'column',
            minHeight: `calc(${theme.spacing(4)}px)`,
        },
        pageRootGrowing: {
            position: 'relative',
            overflow: 'auto',

            display: 'flex',
            flexDirection: 'column',
        },
        pageMain: {
            margin: theme.spacing(1 / 4), //small margin to make sure shadows are shown.
            marginBottom: theme.spacing(1),
            '&:first-child': {
                marginTop: theme.spacing(2),
            },
            '&:last-child': {
                marginBottom: theme.spacing(1),
            },
        },
        pageMainTight: {
            marginTop: 0,
            marginBottom: 0,
            margin: theme.spacing(1 / 4), //small margin to make sure shadows are shown.
        },
        pageMainFlat: {},
        pageSubBordered: {
            borderWidth: 0,
            borderStyle: 'solid',
            borderTopWidth: 1,
            borderColor: 'black',
            borderRadius: 0,
            '&:first-child': {
                borderTopStyle: 'hidden',
            },
        },
        pageFill: {
            height: '100%',
        },

        pageRootMinimal: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            position: 'relative',
            overflow: 'auto',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
        },

        viewRoot: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
            },
        },
        noBordersViewRoot: {
            width: `100%`,
            height: `100%`,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            margin: 0,
            marginRight: 0,
            marginTop: 0,
        },
        toolbarDiv: {
            marginTop: -theme.spacing(3),
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.grey[300],
            borderBottomStyle: 'solid',
            minHeight: 48,
        },
        toolbar: {
            minHeight: 48,
        },
        insidePaper: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1 / 4),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        hasSubPaperList: {

        },
        insidePaperNoHeader: {
            paddingTop: theme.spacing(2),
        },
        insidePaperGutterBottom: {
            paddingBottom: theme.spacing(1/4)
        },
        paperTitleListItem: {
            paddingTop: 2,
            paddingBottom: 2,
            minHeight: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },

        },
        insidePaperNoTop: {
            margin: theme.spacing(2),
        },

        formLayoutDialog: {
            width: 'auto',
            display: 'block', // Fix IE11 issue.
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        formDialogContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            padding: theme.spacing(3),
        },
        formTightDialogContent: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            padding: theme.spacing(1),
        },
        formLayoutWideDialog: {
            width: 'auto',
            display: 'block', // Fix IE11 issue.
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            /*[theme.breakpoints.up(500 + theme.spacing(4) * 2)]: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },*/
        },
        formWideDialogContent: {
            paddingLeft: theme.spacing(18),
            paddingRight: theme.spacing(18),
            padding: theme.spacing(4),
        },
        formLayoutFullScreen: {
            display: 'block',
        },
        formdiv: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        formAvatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },

        linkButton: {
            background: 'none!important',
            border: 'none',
            margin: theme.spacing(0),
            padding: theme.spacing(0),

            /*optional*/
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            cursor: 'pointer',
            textAlign: 'left',
            //minHeight: theme.spacing(4),
        },

        largeLink: {
            minHeight: theme.spacing(4),
            display: 'flex',
            margin: theme.spacing(0.25),
            width: 'fit-content',
            alignItems: 'center',
        },
        errPopOverText: {
            padding: theme.spacing(2),
        },


        /*stepperIcon:{
            "&$stepperActiveIcon": {
                color: theme.palette.secondary.main
            },
            "&$stepperCompletedIcon": {
                color: theme.palette.secondary.main
            }
        },*/
        stepperActiveIcon: {},
        stepperCompletedIcon: {},
        chip: {
            marginRight: theme.spacing(1),
        },
        tableCellDiv: {
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
        },

        extraOptionGroup: {
            margin: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        },
        mobileStepper: {
            position: 'fixed',
            width: '100vw',
            bottom: '0%',
        },
        mobileStepperDisplacer: {
            minHeight: 60+theme.spacing(5)-theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                minHeight: 60+theme.spacing(3)
            },
            [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
                minHeight: 60+theme.spacing(2)
            },
        },
        responsiveStepper: {
            display: 'flex',
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
                paddingBottom: theme.spacing(2),
            },
            [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
                paddingBottom: theme.spacing(1),
            },
        },
        mobileStepperButton: {
            padding: 0,
            margin: 0,
            marginLeft: -6,
            width: 'calc(100% + 12px)',
            overflowX: 'hidden',
            '&>span': {
                width: '100%'
            }
        },
        homeGrid: {
            display: 'grid',
            flexDirection: 'column',
            flexWrap: 'wrap',
            position: 'relative',
            paddingTop: theme.spacing(1),
            flex: '1 1 auto',
            gridTemplateColumns: 'auto auto',
            gridGap: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'auto',
            },
        },
        homeGridElement: {
            margin: 2,
            maxWidth: `calc(100% - 4px)`,
            height: 'fit-content',
        },
        elemHidden: {
            display: 'none',
        },
        bottomButtonDiv: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',

            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1),
            },
        },
        bottomButton: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),

            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(0.5),
            },
        },

        markdownBody: {
            fontSize: '1rem',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
        },

        socialMediaBanner: {
            display: 'flex',
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(0),
            margin: theme.spacing(1),
        },
        shareDiv: {
            marginLeft: theme.spacing(0.5),
        },
        shareWhatsapp: {
            background: '#25D366',
            color: '#fff',
            height: 20,
            border: 1,
            borderColor: '#25D366',
            borderStyle: 'solid',
            borderRadius: 3,
            lineHeight: '18px',
            padding: 0,
            paddingLeft: 4,
            paddingRight: 4,
            fontSize: 11,
            fontFamily: ['Helvetica', 'Arial', 'sans-serif'],
        },
        manualEntry: {
            backgroundColor: '#ffe0b2',

        }
    });
};
