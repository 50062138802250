// @flow

import {BASE_URL} from "../../constants";
import {ConnectStore} from "../BaseStore";
import {makeObservable, observable, computed, action} from "mobx";
import {DictionaryStore} from "./DictionaryStore";
import type {FetcherBase} from "../../Fetchers/FetcherBase";

export class DictionaryManager extends ConnectStore {
    loadedLanguageDict: Map<string, Map<string, string>> = observable.map(new Map(), {deep: false});
    languageList: ?Map<string, string> = undefined;
    static get_lang_list: string = '/dictionary/language-list';
    allDictionaries: Map<string, DictionaryStore> = observable.map(new Map());

    constructor(conn: FetcherBase) {
        super(conn);
        makeObservable(this, {
            languageList: observable,
            showDebug: computed,
            showDebugForced: computed,
            showDebugDisplay: computed,
            allDictionaries: observable,
            addDictionaryGroup: action,
            loadLanguage: action,
            loadLangList: action,
        });
    }

    get showDebug(): boolean {
        // $FlowFixMe
        return this.allDictionaries.size > 0 && this.allDictionaries.values().next().value.showDebug;
    }
    set showDebug(v: boolean) {
        for (const d of this.allDictionaries.values()) {
            d.showDebug = v;
        }
    }
    get showDebugForced(): boolean {
        // $FlowFixMe
        return this.allDictionaries.size > 0 && this.allDictionaries.values().next().value.showDebugForced;
    }
    set showDebugForced(v: boolean) {
        for (const d of this.allDictionaries.values()) {
            d.showDebugForced = v;
        }
    }
    get showDebugDisplay(): boolean {
        // $FlowFixMe
        return this.allDictionaries.size > 0 && this.allDictionaries.values().next().value.showDebugDisplay;
    }
    set showDebugDisplay(v: boolean) {
        for (const d of this.allDictionaries.values()) {
            d.showDebugDisplay = v;
        }
    }

    addDictionaryGroup(group: string): DictionaryStore {
        const o = this.allDictionaries.get(group);
        if (o === undefined) {
            const n = new DictionaryStore(this.conn, group);
            this.allDictionaries.set(group, n);
            return n;
        } else {
            return o;
        }
    }

    async loadLanguage(key: string) {
        const p: Array<Promise<mixed>> = [];
        for (const d of this.allDictionaries.values()) {
            if (!d.hasLanguage(key)) {
                p.push(d.loadLanguage(key));
            }
        }
        await Promise.all(p);
    }

    getDictionary(group: string): DictionaryStore | void {
        return this.allDictionaries.get(group);
    }

    async loadLangList() {
        const lanList = await this.conn.fetchData(
            BASE_URL() + DictionaryManager.get_lang_list,
            {method: 'Get',}
        );
        if (lanList && typeof lanList === 'object' && !Array.isArray(lanList)) {
            this.languageList = observable.map(lanList);
        }
    }

    getLanguageList(): ?Map<string, string> {
        return this.languageList;
    }

    getLanguageName(key: string): string|void {
        return this.languageList ? this.languageList.get(key) : undefined;
    }
}