// @flow

import * as React from "react";
import {observer} from "mobx-react-lite";
import {useCookies} from "react-cookie";
import {DictionaryStoreCtx} from "../../stores/dictionary/DictionaryStoreCtx";
import {LanguageCtx} from "../../stores/LanguageCtx";
import {SingleCookieSetting} from "./SingleCookieSetting";



export const CookieSettings: React.AbstractComponent<{||}> = observer(function CookieSettings() {
    const dictionaryStore = React.useContext(DictionaryStoreCtx);
    const [language,] = React.useContext(LanguageCtx);
    const [, setCookie, ] = useCookies(["consentfunctional"]);
    React.useEffect(() => {
        // Opening the cookie settings shows that one has at least seen the cookie settings
        // So we can set the functional cookies to true. In effect hiding the banner for future components
        setCookie("consentfunctional", "1", {expires: new Date('01-01-2038'), path: '/'});
    }, [setCookie]);

    return <>
        <SingleCookieSetting
            cookie={"consentfunctional"}
            name={dictionaryStore.getFallBack(language, 'hdr-cookie-functional', 'Functional')}
            help={dictionaryStore.getFallBack(language,
                'help-cookie-functional',
                'Functional cookies are required for the site to function.\r\nThese are cookies like login cookies.'
            )}
            disabled={true}
        />
        <SingleCookieSetting
            cookie={"consentoptional"}
            name={dictionaryStore.getFallBack(language, 'hdr-cookie-optional', 'Optional')}
            help={dictionaryStore.getFallBack(language, 'help-cookie-optional')}
        />
        <SingleCookieSetting
            cookie={"consentmarketing"}
            name={dictionaryStore.getFallBack(language, 'hdr-cookie-marketing', 'Marketing')}
            help={dictionaryStore.getFallBack(language, 'help-cookie-marketing')}
        />
        <SingleCookieSetting
            cookie={"consentsocialmedia"}
            name={dictionaryStore.getFallBack(language, 'hdr-cookie-socialmedia', 'Social media')}
            help={dictionaryStore.getFallBack(language, 'help-cookie-socialmedia')}
        />

    </>;
});
