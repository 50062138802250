// @flow

import * as React from "react";
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router";
import {NoLanguageView} from "./NoLanguageView";
import moment from "moment";
import {LanguageView} from "./LanguageView";
import {locale_regex} from "./languageHelpers.js";
moment.locale("en");

type PropTy = {
    defaultLanguage?: string,
}




export const LanguageRouter: React.AbstractComponent<$Shape<PropTy>> = observer(function LanguageRouter(props: PropTy) {
    const {defaultLanguage='en-us'} = props;
    const dl = defaultLanguage.match(locale_regex) ? defaultLanguage : 'en-us';

    return <div>
        <Routes>
            <Route path={"/:lang/*"} element={<LanguageView defaultLanguage={dl}/>}/>
            <Route path={"*"} element={<NoLanguageView defaultLanguage={dl}/>}/>
        </Routes>
    </div>
});

