// @flow

import * as React from "react";
import {Suspense} from "react";
import {observer} from "mobx-react-lite";
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import {RouteData} from "../../models/RouteData";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import CalenderIcon from "@material-ui/icons/Event";
import {ShoppingCartBadgeIcon} from "../sidebar/ShoppingCartBadgeIcon";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import {LanguageCtx} from "../../stores/LanguageCtx";
import {TopBar} from "../topbar/TopBar";
import {SideBar} from "../sidebar/SideBar";
import {CookieBanner} from "../cookie-consent/CookieBanner";
import type {ThemeTy} from "../../custom-types/TypeDefs";
import {makeStyles} from "@material-ui/core/styles";
const MainEntryContextBase = React.lazy(() => import('../main/MainEntryContextBase'));

type LanguageContentPropTy = {
    language?: ?string,
}
const styles = (theme:ThemeTy) => {
    return ({
        root: {
            display: 'flex',
            position: 'relative',
        },
    })
};
const useStyles = makeStyles(styles);

export const LanguageContent: React.AbstractComponent<LanguageContentPropTy> = observer(function(props: LanguageContentPropTy) {
    const {language} = props;
    const classes = useStyles();
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    const dictionaryStore = dictionaryManager.getDictionary("entry"); // entry dict also holds main sidebar stuff
    const nav = useNavigate();

    const shownLanguage = language || 'en-us';
    const matchLanguage = language ? '/' + language : "";

    const isSmallScreen:boolean = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [open_side, setOpenSide] = React.useState(true);
    const [manualSide, setManualSide] = React.useState(false);
    const doLoginCBRef = React.useRef<?((boolean) => mixed)>();
    const [loginState, setLoginState] = React.useState<boolean>(false);
    const [extraTopSideBarItems, setExtraTopSideBarItems] = React.useState<Array<RouteData|React.Node>>([]);
    const [entryCount, setEntryCount] = React.useState<number>(0);
    const [languageProviderState, setLanguageProviderState] = React.useState([shownLanguage, matchLanguage]);
    React.useEffect(() => {
        setLanguageProviderState([shownLanguage, matchLanguage]);
    }, [shownLanguage, matchLanguage]);

    const hasShownLanguage = dictionaryStore && dictionaryStore.hasLanguage(shownLanguage);
    const debug = dictionaryManager.showDebug;

    const topSideBarItems: Array<RouteData|React.Node> = React.useMemo(
        () => {
            return [
                new RouteData({
                    icon: <HomeIcon/>,
                    url: matchLanguage + '/home',
                    name: hasShownLanguage && dictionaryStore ?
                        dictionaryStore.get(shownLanguage, '/home', 'Home') : '',
                }),
                new RouteData({
                    icon: <BusinessIcon/>,
                    url: matchLanguage + '/organizations',
                    name: hasShownLanguage && dictionaryStore ?
                        dictionaryStore.get(shownLanguage, '/organizations', 'Organizations') : '',
                    viewHandlesSubRoutes: true,
                }),
                new RouteData({
                    icon: <CalenderIcon/>,
                    url: matchLanguage + '/events',
                    name: hasShownLanguage && dictionaryStore ?
                        dictionaryStore.get(shownLanguage, '/events', 'Events') : '',
                }),
                new RouteData({
                    icon: <ShoppingCartBadgeIcon count={entryCount}/>,
                    url: matchLanguage + '/entry',
                    name: hasShownLanguage && dictionaryStore ?
                        dictionaryStore.get(shownLanguage, '/entry', 'SportEntry') : '',
                }),
                ...extraTopSideBarItems,
            ]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [debug, matchLanguage, shownLanguage, dictionaryStore, hasShownLanguage, extraTopSideBarItems, entryCount]
    );

    const bottomSideBarItems: Array<RouteData|React.Node> = React.useMemo(
        () => {
            return [
                new RouteData({
                    icon: <InfoIcon/>,
                    url: matchLanguage + '/about',
                    name: hasShownLanguage && dictionaryStore ?
                        dictionaryStore.get(shownLanguage, '/about', 'About') : '',
                }),
            ]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [debug, matchLanguage, shownLanguage, dictionaryStore, hasShownLanguage]
    );


    React.useEffect(() => {
        if (matchLanguage !== '/' && matchLanguage !== "") {
            const [lan, country] = shownLanguage.slice().split('-');
            if (lan && country) {
                if (lan !== 'en') {
                    // $FlowFixMe
                    import('moment/locale' + lan).then(() => {
                        moment.locale(lan + '-' + country);
                    }).catch(() => {
                        console.log('Momentjs language definition for ' + lan + '-' + country + ' not found');
                    })
                } else {
                    moment.locale('');
                }
            } else {
                moment.locale('');
            }
        } else {
            //default
            moment.locale('en');
        }
    }, [matchLanguage, shownLanguage]);


    // React.useEffect(() => {
    //     if (!scoreDictionaryStore.hasLanguage(shownLanguage)) {
    //         scoreDictionaryStore.loadLanguage(shownLanguage)
    //             .then(()=>{})
    //             .catch(e => console.log(e));
    //     }
    // }, [scoreDictionaryStore, shownLanguage]);


    function setDoLoginCB(newCallBack: ?((boolean) => mixed)) {
        doLoginCBRef.current = newCallBack;
    }
    function handleDrawerOpen() {
        setOpenSide(true);
        if (!manualSide) {
            setManualSide(true);
        }
    }

    function handleDrawerClose() {
        setOpenSide(false);
        if (!manualSide) {
            setManualSide(true);
        }
    }

    function handleToggleDrawer() {
        setOpenSide(open => !open);
        if (!manualSide) {
            setManualSide(true);
        }
    }

    function handleSelectMenuItem() {
        if (isSmallScreen) {
            handleDrawerClose();
        }
    }

    function openAccountManagement() {
        nav('account');
    }
    function openMessageCenter() {
        nav('messages');
    }

    async function login() {
        const doLoginCB = doLoginCBRef.current;
        if (doLoginCB) {
            doLoginCB(true);
        }
    }
    async function logout() {
        const doLoginCB = doLoginCBRef.current;
        if (doLoginCB) {
            doLoginCB(false);
        }
    }

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'absolute',
    }}>
        <LanguageCtx.Provider value={languageProviderState}>
            <div style={{
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: 'auto',
                height: 'auto'
            }}>
                {<TopBar
                    onOpenDrawer={handleDrawerOpen}
                    onRequestLogin={login}
                    onRequestLogout={logout}
                    onOpenAccountManagement={openAccountManagement}
                    onOpenMessageCenter={openMessageCenter}
                    currentLanguage={shownLanguage}
                    loggedIn={!!loginState}
                />}
            </div>
            <div className={classes.root} style={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: 'auto',
                overflowY: 'auto',
            }}>
                <SideBar onToggleDrawer={handleToggleDrawer}
                         open={manualSide ? open_side : !isSmallScreen}
                         onSelectElement={handleSelectMenuItem}
                         items={topSideBarItems}
                         bottomItems={bottomSideBarItems}
                />
                <Suspense fallback={<div>Loading...</div>}>
                    <MainEntryContextBase
                        setDoLoginCB={setDoLoginCB}
                        setLoginState={setLoginState}
                        expandTopSideBarElemsCB={setExtraTopSideBarItems}
                        setEntryCount={setEntryCount}
                    />
                </Suspense>

            </div>
            <CookieBanner/>
        </LanguageCtx.Provider>
    </div>

});