// @flow
import * as React from 'react';
import type {RouteDataOptions} from "../custom-types/TypeDefs";

export class RouteData {
    url: string;
    name: React.Node;
    icon: ?React.Node;

    constructor(options: RouteDataOptions) {
        this.url = options.url;
        this.name = options.name || "";
        this.icon = options.icon;
    }
}
