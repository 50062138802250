
import * as React from 'react';
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

type PropTy = {
    count: number
}

export const ShoppingCartBadgeIcon: React.AbstractComponent<PropTy> = function (prop: PropTy) {
    const {count} = prop;
    return <Badge
        color="secondary"
        badgeContent={count}
    >
        <ShoppingCartIcon/>
    </Badge>
};
