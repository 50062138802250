// @flow

import * as React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import {observer} from 'mobx-react-lite';
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import {LanguageRouter} from "./LanguageRouter";


export const Main: React.AbstractComponent<$Shape<{||}>> = observer(function Main() {
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    let dictionaryInitialLoaded = React.useRef(false);
    let dictionaryIsLoading = React.useRef(false);

    React.useEffect(() => {
        if (!dictionaryInitialLoaded.current && !dictionaryIsLoading.current) {
            dictionaryIsLoading.current = true;
            dictionaryManager.loadLangList().catch(e => console.log(e)).finally(() => {
                dictionaryInitialLoaded.current = true;
                dictionaryIsLoading.current = false;
            });
        }
    }, [dictionaryInitialLoaded, dictionaryManager, dictionaryIsLoading]);


    return <>
        <CssBaseline/>
        <LanguageRouter defaultLanguage={
            navigator.language.toLowerCase() ||
            // $FlowFixMe
            navigator.userLanguage.toLowerCase()
        }/>
    </>;
});
