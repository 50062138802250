// @flow
import * as React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";


export const StartId: React.AbstractComponent<$Shape<{||}>> = () => (
    <SvgIcon viewBox="0 0 100 100">

        {/*<path className="st0" d="M13.51,28.11v72.98H0V28.11H13.51z M0,3.79v16.22h13.51V3.79H0z M100,52.44c0-5.97-4.84-10.81-10.81-10.81
	s-10.81,4.84-10.81,10.81s4.84,10.81,10.81,10.81S100,58.41,100,52.44z M89.19,71.36c-4.42,0-8.49-1.53-11.71-4.08
	c-5.39,10.44-16.27,17.59-28.83,17.59h0v0H37.84V20.01l0,0h10.81v0c12.56,0,23.45,7.15,28.83,17.59c3.22-2.55,7.28-4.08,11.71-4.08
	c1.56,0,3.06,0.21,4.51,0.56C86.46,16.32,69.02,3.79,48.65,3.79l-10.81,0l0,0H21.62v97.3h27.03l0,0c4.29,0,15.89-0.56,27.45-8.48
	c7.81-5.35,13.98-12.93,17.6-21.81C92.25,71.15,90.75,71.36,89.19,71.36z"/>*/}
        <path className="st0" d="M23.29,31.25V87.5H12.88V31.25H23.29z M12.88,12.5V25h10.42V12.5H12.88z M89.96,50c0-4.6-3.73-8.33-8.33-8.33
	c-4.6,0-8.33,3.73-8.33,8.33s3.73,8.33,8.33,8.33C86.23,58.33,89.96,54.6,89.96,50z M81.63,64.58c-3.41,0-6.54-1.18-9.02-3.14
	C68.45,69.49,60.06,75,50.38,75h0v0h-8.33V25l0,0h8.33v0c9.68,0,18.07,5.51,22.23,13.56c2.48-1.96,5.61-3.14,9.03-3.14
	c1.2,0,2.36,0.16,3.48,0.43C79.52,22.16,66.08,12.5,50.38,12.5l-8.33,0l0,0h-12.5v75h20.83l0,0c3.3,0,12.25-0.43,21.16-6.54
	c6.02-4.13,10.78-9.96,13.57-16.81C83.99,64.42,82.83,64.58,81.63,64.58z"/>

    </SvgIcon>
);
