// @flow

import {Button, useMediaQuery} from "@material-ui/core";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {DictionaryManagerCtx} from "../../stores/dictionary/DictionaryManagerCtx";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";
import type {LanguageButtonPropTy} from "./LanguageSelect";

const styles = (theme: ThemeTy) => ({
    list: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    button: {
        width: '100%',
        justifyContent: 'start'
    }
});

type PropTy = {
    onChange: (SyntheticEvent<HTMLAnchorElement>, value: string) => mixed,
    languageButtonComponent?: React.AbstractComponent<LanguageButtonPropTy>,
}

const useLocalStyles = makeStyles(styles);


const LanguageButton = function(props: LanguageButtonPropTy) {
    const {value, name, onSelect} = props;
    const localClasses: CssClassesTy = useLocalStyles();
    return <li><Button
        onClick={(ev) => {onSelect(ev, value);}}
        key={value}
        className={localClasses.button}
    >
        {name}
    </Button></li>
};

export const LanguageSelectList: React.AbstractComponent<PropTy> = observer(function LanguageSelectMenu(props: PropTy) {
    const {onChange, languageButtonComponent:Component=LanguageButton} = props;
    const localClasses: CssClassesTy = useLocalStyles();
    const dictionaryManager = React.useContext(DictionaryManagerCtx);
    const smallScreen:boolean = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const langList = dictionaryManager.getLanguageList();
    const tmap = new Map();
    if (langList) {
        for (const [k, v] of langList.entries()) {
            tmap.set(v, k);
        }
    }
    const languageStringsSorted: string[] = langList ? Array.from(langList.values()).sort() : [];
    const l = languageStringsSorted.length;
    const numRows =  l < 5 ? l : (l < 10 ? Math.ceil(l/2) : Math.ceil(l / 3));
    const firstCol = smallScreen ? languageStringsSorted : languageStringsSorted.slice(0, numRows);
    const secondCol = smallScreen ? [] : languageStringsSorted.slice(numRows, numRows*2);
    const thirdCol = smallScreen ? [] : languageStringsSorted.slice(numRows*2);

    return <div className={localClasses.mainDiv}>{<>
        <ul className={localClasses.list}>
            {firstCol.map(t =>
                <Component value={tmap.get(t) || t} name={t} onSelect={onChange} key={t}/>
            )}
        </ul>
        {!smallScreen &&<>
            <ul className={localClasses.list}>
                {secondCol.map(t =>
                    <Component value={tmap.get(t) || t} name={t} onSelect={onChange} key={t}/>
                )}
            </ul>
            <ul className={localClasses.list}>
                {thirdCol.map(t =>
                    <Component value={tmap.get(t) || t} name={t} onSelect={onChange} key={t}/>
                )}
            </ul>
        </>}
    </>}</div>;
});
