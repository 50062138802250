// @flow

import Paper from "@material-ui/core/Paper";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {styles as outer_styles} from "../styles";
import type {CssClassesTy} from "../../custom-types/TypeDefs";
import type {LanguageButtonPropTy} from "./LanguageSelect";
import {LanguageSelectList} from "./LanguageSelectList";


type PropTy = {
    onChange: (SyntheticEvent<HTMLAnchorElement>, value: string) => mixed,
    languageButtonComponent?: React.AbstractComponent<LanguageButtonPropTy>,
}
const useStyles = makeStyles(outer_styles);

export const LanguageSelectPaper: React.AbstractComponent<$Shape<PropTy>> = function LanguageSelectPaper(props: PropTy) {
    const {onChange, languageButtonComponent} = props;
    const classes: CssClassesTy = useStyles();

    return <Paper className={classes.formDialogContent}>
        <LanguageSelectList onChange={onChange} languageButtonComponent={languageButtonComponent}/>
    </Paper>;
};
