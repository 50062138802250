// @flow

import * as React from 'react';
import {CookiesProvider} from "react-cookie";
import {Helmet, HelmetProvider } from "react-helmet-async";
import {Route, Routes} from 'react-router';
import {BrowserRouter} from "react-router-dom";

import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import DateUtils from "@date-io/moment";
import {MuiPickersUtilsProvider,} from "@material-ui/pickers";
import {FetcherUrl} from "./Fetchers/FetcherUrl";
import {DictionaryManager} from "./stores/dictionary/DictionaryManager";
import {DictionaryManagerCtx} from "./stores/dictionary/DictionaryManagerCtx";
//
import {Main} from "./components/AllSportsMain/Main";
// import {RouterStoreCtx} from "./stores/RouterStoreCtx";


require('format-unicorn');

const theme = createTheme({
    palette: {
        primary: {
            main: '#ED1C24'
        },
        //secondary: indigo,
        secondary: {
            main: '#8F3B96'
        }
    },
    typography: {
        fontWeightMedium: 600,
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#8F3B96',
                }
            },
        },
        MuiTab: {
            root: {
                fontWeight: 'normal',
                '@media (min-width:960px)': {
                    fontSize: undefined, // MUI default makes fontsize for widescreen *smaller*.
                },
            }
        },
    }
});

// function makeRouterStore() {
//     const routerStore = new RouterStore();
//     const _browserHistory = createBrowserHistory();
//     syncHistoryWithStore(_browserHistory, routerStore);
//     return routerStore;
// }

function App(): React.Node {
    //const routerStoreRef = React.useRef<RouterStore>(makeRouterStore());
    const dictionaryManagerRef = React.useRef<DictionaryManager>(new DictionaryManager(new FetcherUrl()));

    return <div style={{height: '100vh', position: 'relative'}}>
        <HelmetProvider>
            <Helmet>
                <title>AllSports</title>
                <meta name="description" content="Search the event calendar from AllSport.app" />
            </Helmet>
            {<MuiThemeProvider theme={theme}>
                {/*<RouterStoreCtx.Provider value={routerStoreRef.current}>*/}
                    <DictionaryManagerCtx.Provider value={dictionaryManagerRef.current}>
                        <CookiesProvider>
                            <MuiPickersUtilsProvider utils={DateUtils}>
                                {<BrowserRouter>{
                                    <Routes>
                                        <Route path={`*`} element={<>
                                            <Main/>
                                        </>}/>
                                    </Routes>
                                }</BrowserRouter>}
                            </MuiPickersUtilsProvider>
                        </CookiesProvider>
                    </DictionaryManagerCtx.Provider>
                {/*</RouterStoreCtx.Provider>*/}
            </MuiThemeProvider>}
        </HelmetProvider>
    </div>;
}

export default App;
