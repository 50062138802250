// @flow

import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {DictionaryStoreCtx} from "../../stores/dictionary/DictionaryStoreCtx";
import {LanguageCtx} from "../../stores/LanguageCtx";
import type {CssClassesTy, ThemeTy} from "../../custom-types/TypeDefs";
import {useCookies} from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import {CookieSettings} from "./CookieSettings";

const styles = (theme: ThemeTy) => ({
    stickToBottom: {
        position: 'fixed',
        bottom: 0,
        zIndex: 1000000,
    },
    toolbarOptions: {
        width: '100%',
        backgroundColor: theme.palette.grey[200],
        minHeight: theme.spacing(6),
    },
    cookieSettings: {
        width: 'fit-content',
        minWidth: '33%',
    },
});
const useLocalStyles = makeStyles(styles);

export const CookieBanner: React.AbstractComponent<$Shape<{||}>> = observer(function CookieBanner() {
    const [cookies, setCookie, ] = useCookies(["consentfunctional", "consentoptional", "consentmarketing"]);
    const [handled, setHandled] = React.useState<boolean>(false);
    const [showCookieSettings, setShowCookieSettings] = React.useState<boolean>(false);
    const localClasses:CssClassesTy = useLocalStyles();

    React.useEffect(() => {
        if (cookies.consentfunctional !== undefined) {
            setHandled(true);
        }
    }, [cookies]);
    React.useEffect( () => {

    }, [cookies.consentsocialmedia]);
    const dictionaryStore = React.useContext(DictionaryStoreCtx);
    const [language,] = React.useContext(LanguageCtx);

    function onAccept() {
        setCookie("consentfunctional", "1", {expires: new Date('01-01-2038'), path: '/'});
        setCookie("consentoptional", "1", {expires: new Date('01-01-2038'), path: '/'});
        setCookie("consentmarketing", "1", {expires: new Date('01-01-2038'), path: '/'});
        setCookie("consentsocialmedia", "1", {expires: new Date('01-01-2038'), path: '/'});
    }
    function onReject() {
        setHandled(true);
    }
    function openSettings() {
        setShowCookieSettings(true);
    }
    const handleCloseSettings = () => {
        setShowCookieSettings(false);
    };
    //console.log("BANNER DRAWN")

    return <>{!handled && !showCookieSettings &&
        <Toolbar className={classNames(localClasses.stickToBottom, localClasses.toolbarOptions)}>
            <Typography style={{paddingRight: 8}}>
                {dictionaryStore.getFallBack(language, 'cookie-consent', "AllSports.app uses cookies to improve website performance, do you accept?")}
            </Typography>
            <div>{dictionaryStore.language_counter}</div>
            <Button variant={"contained"} color={"primary"} onClick={onAccept}>{dictionaryStore.getFallBack(language, 'cookie-accept-all', "Accept all")}</Button>
            <Button variant={"outlined"} color={"primary"} onClick={onReject}>{dictionaryStore.getFallBack(language, 'cookie-reject-all', "Reject all")}</Button>
            <Button variant={"outlined"} color={"primary"} onClick={openSettings}>{dictionaryStore.getFallBack(language, 'cookie-open-settings', "Settings")}</Button>
        </Toolbar>
        }
        <Drawer anchor={"bottom"} open={showCookieSettings} onClose={handleCloseSettings}>
            <Toolbar className={classNames(localClasses.toolbarOptions)}>
                <Typography style={{paddingRight: 8}}>
                    {dictionaryStore.getFallBack(language, 'cookie-consent', "AllSports.app uses cookies to improve website performance, do you accept?")}
                </Typography>
                <Button variant={"contained"} color={"primary"} onClick={onAccept}>{dictionaryStore.getFallBack(language, 'cookie-accept-all', "Accept all")}</Button>
                <Button variant={"outlined"} color={"primary"} onClick={onReject}>{dictionaryStore.getFallBack(language, 'cookie-reject-all', "Reject all")}</Button>
            </Toolbar>
            <div className={localClasses.cookieSettings}>
                <CookieSettings/>
            </div>
        </Drawer>
    </>
});
