// @flow


import type {PlainArrayTy, PlainJsonTy} from "../custom-types/TypeDefs";

export type ProtoFetchOptions = {
    method?: string,
    credentials?: CredentialsType,
    body?: $ReadOnly<FormData>|$ReadOnly<{+[string]: mixed}>,
    query?: {[string]: string|string[]},
}

export class FetcherBase {

    static buildFormData(body: $ReadOnly<{[string]: ?mixed}>): FormData {
        const newbody = new FormData();
        for (const [prop, v] of Object.entries(body)) {
            if (typeof v === 'string') {
                newbody.append(prop, v);
            } else if (typeof v === 'number') {
                newbody.append(prop, v.toString(10));
            } else if (typeof v === 'boolean') {
                newbody.append(prop, v ? 'true' : 'false');
            } else if (v instanceof File) {
                newbody.append(prop, v);
            } else if (v instanceof Blob) {
                newbody.append(prop, v);
            }
        }
        return newbody;
    }

    static buildOptions(options:$ReadOnly<ProtoFetchOptions>={}): RequestOptions {
        /*if (!options) {
            options = {};
        }*/

        const exportOpt: RequestOptions = {
            method: options.method || 'GET',
            credentials: options.credentials || (process.env.NODE_ENV === 'production' ? 'same-origin' : 'include')
        };

        const body = options.body;
        if (body && typeof body === 'object') {
            exportOpt.body = (body instanceof FormData) ? body : FetcherBase.buildFormData(body);
        }

        return exportOpt;
    }
    buildUrl(base_url: string, options:$ReadOnly<ProtoFetchOptions>={}): URL {
        const url = new URL(base_url, window.location.href);
        if (options.query) {
            const q = options.query;
            for (const key of Object.keys(q)) {
                const val = q[key];
                if (Array.isArray(val)) {
                    for (const v of val) {
                        url.searchParams.append(key, v);
                    }
                } else {
                    url.searchParams.append(key, val);
                }
            }
        }
        return url;
    }

    async fetchData(base_url: string, options: $ReadOnly<ProtoFetchOptions>): Promise<PlainJsonTy | PlainArrayTy> {
        throw new Error('Not implemented')
    }

    async fetchImageData(base_url: string, options: $ReadOnly<ProtoFetchOptions>): Promise<Blob> {
        throw new Error('Not implemented')
    }
}
