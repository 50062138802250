// @flow

import {useMediaQuery} from "@material-ui/core";
import * as React from 'react'
import {observer} from "mobx-react-lite";
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from "@material-ui/core/styles";

import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {useLocation} from "react-router";
import type {CssClassesTy} from "../../custom-types/TypeDefs";


import {styles as outer_styles} from '../styles'
import {ClickableListItem} from './ClickableListItem'
import {RouteData} from "../../models/RouteData";
import {useMatch} from "react-router-dom";

type PropTy = {
    onToggleDrawer: () => mixed,
    onSelectElement: (string) => mixed,
    open: boolean,
    route: string,
    items?: $ReadOnlyArray<RouteData | React.Node>,
    bottomItems?: $ReadOnlyArray<RouteData | React.Node>,
}
const useStyles = makeStyles(outer_styles);


export const SideBar: React.AbstractComponent<$Shape<PropTy>> = observer(function SideBar(props:PropTy) {
    const { open, items, bottomItems } = props;
    const classes:CssClassesTy = useStyles();
    const location = useLocation();
    const isMobile:boolean = useMediaQuery(theme => theme.breakpoints.down('sm'));

    function handleToggleDrawer(e: SyntheticEvent<HTMLElement>) {
        props.onToggleDrawer();
    }

    function handleSelectElement(e: SyntheticEvent<HTMLElement>, value: string) {
        props.onSelectElement(value);
    }
    const route: string = location.pathname;
    const isHome = !!useMatch('/:lan');


    return <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        classes={{
            paper: classNames(classes.drawerPaper, !isMobile && !open && classes.drawerPaperClose),
        }}
        open={open}
        onClose={handleToggleDrawer}
        style={{display: "flex", flexDirection: "column", overflowX: "off"}}
    >
        <div className={classNames(classes.sideBarOpenIcon, isMobile ? classes.sideBarOpenMobileIcon : {})}>
            <IconButton onClick={handleToggleDrawer}
                        color="inherit"
                        aria-label={"Close drawer"}
            >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </div>
        <Divider />
        <List style={{flexGrow: 1}}>
            {items && items.map((item, idx) => {
                if (item instanceof RouteData) {
                    return (<ClickableListItem
                        text={open ? item.name : undefined}
                        route={item.url}
                        key={item.url || idx}
                        onSelectElement={(e, value) => {
                            handleSelectElement(e, value);
                        }}
                        icon={item.icon}
                        selected={(isHome && idx === 0) || route === item.url}
                    />);
                } else {
                    return <React.Fragment key={idx}>{item}</React.Fragment>;
                }
            })}
        </List>
        <List style={{marginBottom: 0, paddingBottom: 0}}>
            {bottomItems && bottomItems.map((item, idx) => {
                if (item instanceof RouteData) {
                    return (<ClickableListItem
                        text={open ? item.name : undefined}
                        route={item.url}
                        key={item.url || idx}
                        onSelectElement={(e, value) => {
                            handleSelectElement(e, value);
                        }}
                        icon={item.icon}
                        selected={route === item.url}
                    />);
                } else {
                    return <React.Fragment key={idx}>{item}</React.Fragment>;
                }
            })}
        </List>
    </Drawer>;
});
