// @flow

import {observer} from "mobx-react-lite";
import * as React from "react";
//$FlowFixMe
import {Navigate, useLocation} from "react-router";



type LanguageViewProps = {
    defaultLanguage: string,
}
export const NoLanguageView: React.AbstractComponent<$Shape<LanguageViewProps>> = observer(function (props: LanguageViewProps) {
    const {defaultLanguage} = props;
    const loc = useLocation();
    const t = "/" + defaultLanguage + loc.pathname + loc.search + (loc.hash ? '#' + loc.hash : '');
    return <Navigate to={t} state={loc.state}/>
});